import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Burger from "./Burger";
import OffCanvas from "../OffCanvas/OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const MainNav = ({
  headerStyle,
  headerHasBorder,
  headerDarkMode,
  headerLinkColor,
  scrolled,
}) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  // const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  // const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  // const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  // handle click of navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  const data = useStaticQuery(graphql`
    {
      darkLogo: file(relativePath: { eq: "global/Logo Black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
        }
      }
      lightLogo: file(relativePath: { eq: "global/Logo White.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
        }
      }
      mortgageGuide: file(relativePath: { eq: "global/mega-nav-buyers.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 127, placeholder: NONE)
        }
      }
    }
  `);

  // Define logos based on header style
  let initialLogo = null,
    stickyLogo = null,
    className = null;

  if (headerStyle === "overlap" || headerStyle === "overlap-hero") {
    initialLogo = data.lightLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    className = "absolute";
  } else if (headerDarkMode) {
    initialLogo = data.lightLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.lightLogo.childImageSharp.gatsbyImageData;
  } else {
    initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  }

  if (offcanvasOpen) {
    initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  }

  const navigation = {
    loanPrograms: [
      {
        name: "Home Buyer’s Guide",
        href: "/home-purchase/",
      },
      {
        name: "Conventional  Loans",
        href: "/conventional-loans-chicago/",
      },
      {
        name: "FHA Loans",
        href: "/fha-loans-chicago/",
      },
      {
        name: "Jumbo Loans",
        href: "/jumbo-loans-chicago/",
      },
      {
        name: "VA Loans",
        href: "/va-loans-chicago/",
      },
    ],
    about: [
      {
        name: "About",
        href: "/about/",
      },
      {
        name: "Meet the Team",
        href: "/team/",
      },
      {
        name: "Reviews",
        href: "/reviews/",
      },
    ],
  };

  return (
    <nav
      id="main-navigation"
      className={`py-2 lg:py-3 bg-white shadow-5xl lg:shadow-none w-full transition duration-300 ease-linear ${
        headerStyle === "overlap" ? "lg:bg-transparent" : "lg:bg-white"
      } ${
        headerHasBorder && "lg:border-b lg:border-solid lg:border-gray-300"
      } ${offcanvasOpen ? "" : ""} ${headerDarkMode && "lg:!bg-primary-900"} ${
        scrolled && "!fixed !bg-white top-0 left-0 w-full !shadow-5xl z-50"
      } ${className}`}
      role="navigation"
      aria-label="main-navigation"
      offcanvas-open={offcanvasOpen}
    >
      <div className="container flex justify-between items-center">
        <div className="flex-auto flex lg:hidden">
          <a href="tel:773-501-3997">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.58124 0.769072L3.83117 0.0190875C4.18429 -0.0621608 4.54678 0.12221 4.69053 0.453453L6.1905 3.95338C6.32175 4.25962 6.23425 4.61899 5.97488 4.82836L4.08117 6.37833C5.20615 8.77515 7.17173 10.7689 9.61855 11.9157L11.1685 10.022C11.381 9.76263 11.7373 9.67513 12.0435 9.80638L15.5434 11.3063C15.8778 11.4532 16.0622 11.8157 15.9809 12.1688L15.2309 15.4188C15.1528 15.7563 14.8528 16 14.4997 16C6.49674 16 0 9.51576 0 1.50031C0 1.15031 0.240622 0.847195 0.58124 0.769072Z"
                className="fill-current text-primary-900 hover:text-primary-400 transition-colors duration-300 ease-linear"
              />
            </svg>
          </a>
        </div>
        <div className="flex-auto flex justify-center lg:justify-start">
          <AniLink fade to="/">
            <div className={`${scrolled && "hidden"}`}>
              <div className="hidden lg:block">
                <GatsbyImage
                  image={initialLogo}
                  alt="MacAsh Home Loans Logo"
                  className="w-[57px] md:w-[95px]"
                />
              </div>
              <div className="lg:hidden">
                <GatsbyImage
                  image={data.darkLogo.childImageSharp.gatsbyImageData}
                  alt="MacAsh Home Loans Logo"
                  className="w-[57px] md:w-[95px]"
                />
              </div>
            </div>

            <div className={`hidden ${scrolled && "!block"}`}>
              <div className="hidden lg:block">
                <GatsbyImage
                  image={stickyLogo}
                  alt="MacAsh Home Loans Logo"
                  className="w-[57px] md:w-[95px]"
                />
              </div>
              <div className="lg:hidden">
                <GatsbyImage
                  image={data.darkLogo.childImageSharp.gatsbyImageData}
                  alt="MacAsh Home Loans Logo"
                  className="w-[57px] md:w-[95px]"
                />
              </div>
            </div>
          </AniLink>
        </div>
        <div className="flex items-center justify-end flex-auto">
          <ul
            id="navigation-desktop"
            className="hidden lg:flex lg:flex-row lg:space-x-8 lg:items-center lg:justify-end lg:mr-8"
          >
            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu1}
              onMouseLeave={notHoveringSubMenu1}
            >
              <AniLink
                fade
                to="/home-purchase/"
                className={`font-body relative text-sm font-semibold pb-8 after:absolute after:bottom-6 after:h-1 after:bg-primary-400 after:transition-all after:duration-300 after:ease-linear ${
                  subMenuHovering1
                    ? "after:w-full after:left-0 after:right-auto"
                    : "after:w-0 after:left-auto after:right-0"
                } ${scrolled && "text-gray-900"} ${
                  headerLinkColor === "white" ? "text-white" : "text-gray-900"
                } ${headerDarkMode && "lg:!text-white"}`}
              >
                Purchase
              </AniLink>
              <div
                className={`absolute top-0 bg-white shadow-3xl w-auto transform -translate-x-8 p-10 z-10 transition-all duration-300 ease-linear ${
                  subMenuHovering1
                    ? " visible translate-y-14 opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                <div className="">
                  <AniLink
                    fade
                    to="/home-purchase/"
                    className="grid grid-cols-12 gap-x-10 border-b border-gray-100 pb-10 mb-10"
                  >
                    <div className="col-start-1 col-span-8">
                      <div className="font-body text-xl font-medium text-primary-400 mb-2">
                        Buyers Mortgage Guide
                      </div>
                      <p className="text-sm mb-0">
                        Learn the basics, compare loan programs, and get answers
                        to frequently asked questions.
                      </p>
                    </div>

                    <div className="col-end-13 col-span-4">
                      <GatsbyImage
                        image={
                          data.mortgageGuide.childImageSharp.gatsbyImageData
                        }
                        className="z-[-10]"
                      />
                    </div>
                  </AniLink>
                </div>

                <div>
                  <div className="text-gray-300 font-medium uppercase text-sm tracking-wide mb-5">
                    Featured Loan Programs
                  </div>
                  <ul className="flex flex-col space-y-5">
                    {navigation.loanPrograms.slice(1).map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <AniLink
                          fade
                          to={item.href}
                          className="group inline-flex items-center justify-between w-full relative font-body text-xl font-medium text-primary-400 hover:text-primary-400"
                        >
                          <span>{item.name}</span>
                          <i className="far fa-arrow-right text-xl text-gray-900 group-hover:text-primary-400 relative left-0 group-hover:left-2 transition-all duration-300 ease-linear"></i>
                        </AniLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>

            <li className="group">
              <AniLink
                fade
                to="/home-refinance-chicago/"
                className={`font-body relative text-sm font-semibold pb-8 after:absolute after:bottom-6 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:w-0 hover:after:w-full after:h-1 after:bg-primary-400 after:transition-all after:duration-300 after:ease-linear ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white" ? "text-white" : "text-gray-900"
                } ${headerDarkMode && "lg:!text-white"}`}
              >
                Refinance
              </AniLink>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu2}
              onMouseLeave={notHoveringSubMenu2}
            >
              <AniLink
                fade
                to="/about/"
                className={`font-body relative text-sm font-semibold pb-8 after:absolute after:bottom-6 after:h-1 after:bg-primary-400 after:transition-all after:duration-300 after:ease-linear ${
                  subMenuHovering2
                    ? "after:w-full after:left-0 after:right-auto"
                    : "after:w-0 after:left-auto after:right-0"
                } ${scrolled && "text-gray-900"} ${
                  headerLinkColor === "white" ? "text-white" : "text-gray-900"
                } ${headerDarkMode && "lg:!text-white"}`}
              >
                About
              </AniLink>
              <ul
                className={`absolute top-0 bg-white shadow-3xl flex flex-col space-y-4 w-auto transform -translate-x-8 px-8 pt-8 pb-8 z-10 transition-all duration-300 ease-linear ${
                  subMenuHovering2
                    ? " visible translate-y-14 opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.about.slice(1).map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <AniLink
                      fade
                      to={item.href}
                      className="relative block font-body font-semibold text-gray-500 hover:text-primary-400"
                    >
                      {item.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </li>

            <li className="group">
              <AniLink
                fade
                to="/blog/"
                className={`font-body relative text-sm font-semibold pb-8 after:absolute after:bottom-6 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:w-0 hover:after:w-full after:h-1 after:bg-primary-400 after:transition-all after:duration-300 after:ease-linear ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white" ? "text-white" : "text-gray-900"
                } ${headerDarkMode && "lg:!text-white"}`}
              >
                Resources
              </AniLink>
            </li>
          </ul>

          <div className="hidden lg:inline-flex lg:items-center lg:space-x-4">
            {headerDarkMode ? (
              <ButtonGhost
                modal="modal-contact"
                text="Contact"
                altStyle={true}
                className="min-w-0"
              />
            ) : (
              <ButtonSolid
                modal="modal-contact"
                text="Contact"
                altStyle={true}
                className="min-w-0"
              />
            )}

            <ButtonSolid
              href="/request-rates/"
              text="Request Rates"
              className="min-w-0"
            />
          </div>

          <div className="lg:hidden" ref={node}>
            {/* Burger */}
            <Burger
              offcanvasOpen={offcanvasOpen}
              setOffcanvasOpen={setOffcanvasOpen}
              headerStyle={headerStyle}
              scrolled={scrolled}
              aria-controls="offcanvas-navigation"
            />

            {/* Mobile Nav */}
            <OffCanvas offcanvasOpen={offcanvasOpen} id="offcanvas-navigation">
              <div>
                <ul id="navigation-mobile" className="mb-12">
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-7"
                  >
                    <li>
                      <AccordionItem uuid={1}>
                        <AccordionItemButton className="flex items-center focus:outline-none">
                          <p className="font-heading text-3xl text-primary-800 hover:text-primary-400 font-bold no-underline mb-0 transition-colors duration-300 ease-linear">
                            Purchase
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-6">
                          <ul className="flex flex-col space-y-3">
                            {navigation.loanPrograms.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <AniLink
                                  fade
                                  to={item.href}
                                  className="relative block font-body font-semibold text-gray-500 hover:text-primary-400"
                                >
                                  {item.name}
                                </AniLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <AniLink
                        fade
                        to="/home-refinance-chicago/"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-3xl text-primary-800 hover:text-primary-400 font-bold no-underline transition-colors duration-300 ease-linear"
                      >
                        Refinance
                      </AniLink>
                    </li>

                    <li>
                      <AccordionItem uuid={2}>
                        <AccordionItemButton className="flex items-center focus:outline-none">
                          <p className="font-heading text-3xl text-primary-800 hover:text-primary-400 font-bold no-underline mb-0 transition-colors duration-300 ease-linear">
                            About
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-6">
                          <ul className="flex flex-col space-y-3">
                            {navigation.about.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <AniLink
                                  fade
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="relative block font-body font-semibold text-gray-500 hover:text-primary-400"
                                >
                                  {item.name}
                                </AniLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <AniLink
                        data-modal-open="modal-contact"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-3xl text-primary-800 hover:text-primary-400 font-bold no-underline cursor-pointer transition-colors duration-300 ease-linear"
                      >
                        Contact
                      </AniLink>
                    </li>
                  </Accordion>
                </ul>

                <ButtonSolid
                  href="/request-rates/"
                  text="Request Rates"
                  className="w-full"
                />
              </div>
            </OffCanvas>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainNav;
