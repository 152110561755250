import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import ModalContact from "../Modal/ModalContact";
import nestLogo from "../../images/global/Nest Logo.svg";

const Footer = ({ hideFooter }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);

  const citiesClickHandler = () => {
    setCityDropdownOpen(!cityDropdownOpen);
  };

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "global/Logo White.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 119)
        }
      }
      equalHousingOpportunity: file(
        relativePath: { eq: "global/Equal-Housing-Logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 52)
        }
      }
    }
  `);

  const navigation = {
    homeLoans: [
      {
        name: "Jumbo Loans",
        href: "/jumbo-loans-chicago/",
      },
      {
        name: "Conventional Loans",
        href: "/conventional-loans-chicago/",
      },
      {
        name: "FHA Loans",
        href: "/fha-loans-chicago/",
      },
      {
        name: "VA Loans",
        href: "/va-loans-chicago/",
      },
    ],
    company: [
      {
        name: "About",
        href: "/about/",
      },
      {
        name: "Meet the Team",
        href: "/team/",
      },
      {
        name: "Reviews",
        href: "/reviews/",
      },
      {
        name: "Blog",
        href: "/blog/",
      },
    ],
    apply: [
      {
        name: "Request Rates",
        href: "/request-rates/",
      },
    ],
    cities: [
      {
        name: "Chicago",
        href: "/chicago-mortgage-broker/",
      },
      {
        name: "De Plaines",
        href: "/de-plaines-mortgage-lender/",
      },
      {
        name: "Morton Grove",
        href: "/morton-grove-mortgage-lender/",
      },
      {
        name: "Niles",
        href: "/niles-mortgage-lender/",
      },
      {
        name: "Northbrook",
        href: "/northbrook-mortgage-lender/",
      },
      {
        name: "Park Ridge",
        href: "/park-ridge-mortgage-lender/",
      },
    ],
  };

  return (
    <>
      <footer
        className={`bg-primary-900 pt-20 lg:pt-20 pb-12 lg:pb-6 ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          <div className="grid lg:grid-cols-12 lg:justify-between space-y-16 lg:space-y-0 lg:space-x-8 mb-20 md:mb-16">
            <div className="lg:col-start-1 lg:col-span-3">
              <div className="grid grid-cols-2 md:flex md:flex-col items-end md:items-start">
                <AniLink fade to="/">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="MacAsh Home Loans Logo"
                    className="lg:mb-8"
                  />
                </AniLink>

                <div className="flex items-center space-x-6">
                  <a
                    href="https://www.instagram.com/macashhomeloans/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 border border-white rounded-full flex justify-center items-center text-white hover:bg-primary-400 transition-colors duration-300 ease-linear"
                  >
                    <i class="fab fa-instagram text-3xl"></i>
                  </a>

                  <a
                    href="https://www.facebook.com/MacAshHomeLoans1/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 border border-white rounded-full flex justify-center items-center text-white hover:bg-primary-400 transition-colors duration-300 ease-linear"
                  >
                    <i class="fab fa-facebook-f text-3xl"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:col-end-13 md:col-span-9">
              <div className="grid lg:flex lg:justify-between grid-cols-2 gap-x-10 lg:gap-x-0 gap-y-16 lg:gap-y-0">
                <div>
                  <div className="font-heading text-lg text-white font-bold tracking-wider mb-5">
                    Home Loans
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.homeLoans.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <AniLink
                          fade
                          to={item.href}
                          className="relative text-sm text-white hover:text-primary-300"
                        >
                          {item.name}
                        </AniLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="font-heading text-lg text-white font-bold tracking-wider mb-5">
                    Company
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.company.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <AniLink
                          fade
                          to={item.href}
                          className="relative text-sm text-white hover:text-primary-300"
                        >
                          {item.name}
                        </AniLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="font-heading text-lg text-white font-bold tracking-wider mb-5">
                    Apply
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.apply.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <a
                          href={item.href}
                          className="relative text-sm text-white hover:text-primary-300"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                    <li className="whitespace-nowrap">
                      <a
                        href="https://www.blink.mortgage/app/signup/p/macashhomeloansllc"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative text-sm text-white hover:text-primary-300"
                      >
                        Apply Now
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="lg:pl-6 xl:pl-10">
                  <div className="font-heading text-lg text-white font-bold tracking-wider mb-5">
                    Contact
                  </div>

                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        href="mailto:info@macashhomeloans.com"
                        className="relative text-sm text-white hover:text-primary-300"
                      >
                        info@macashhomeloans.com
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:773-501-3997"
                        className="relative text-sm text-white hover:text-primary-300"
                      >
                        (773) 501-3997
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap flex-col lg:flex-row justify-items-center lg:justify-between lg:items-center lg:pt-4">
            <div className="lg:flex lg:items-center mb-8 lg:mb-0">
              <ul className="flex items-center flex-wrap space-x-4 justify-center lg:justify-start">
                <li className="text-xs text-gray-50">
                  © {getYear()} MacAsh Home Loans
                </li>

                <li className="text-xs">
                  <AniLink
                    fade
                    to="/privacy-policy/"
                    className="text-gray-50 hover:text-primary-400 no-underline"
                  >
                    Privacy Policy
                  </AniLink>
                </li>

                <li className="text-sm text-gray-50">
                  <button
                    className="relative focus:outline-none font-normal text-gray-50 hover:text-primary-400 no-underline"
                    onKeyDown={citiesClickHandler}
                    onClick={citiesClickHandler}
                  >
                    <span className="flex items-center justify-between">
                      Cities
                      <i
                        className={`far fa-chevron-down ml-2 transition-all duration-300 ease-linear transform ${
                          cityDropdownOpen ? "rotate-180" : "rotate-0"
                        }`}
                      ></i>
                    </span>
                    <ul
                      className={`bg-white text-left absolute bottom-0 right-0 pt-5 px-4 pb-4 flex flex-col space-y-1.5 max-h-28 overflow-y-scroll transform transition-all duration-300 ease-linear ${
                        cityDropdownOpen
                          ? "opacity-100 -translate-y-10 visible"
                          : "opacity-0 -translate-y-4 invisible"
                      }`}
                    >
                      {navigation.cities.map((item) => (
                        <li
                          className="text-xs whitespace-nowrap pb-1.5"
                          key={item.name}
                        >
                          <AniLink
                            fade
                            to={item.href}
                            className="font-heading text-gray-900 hover:text-primary-400"
                          >
                            {item.name}
                          </AniLink>
                        </li>
                      ))}
                    </ul>
                  </button>
                </li>
                <li className="text-xs w-full md:w-auto mt-6 md:mt-0">
                  <a
                    className="no-underline text-gray-50 hover:text-primary-400 flex items-center justify-center lg:justify-start space-x-1"
                    href="https://www.wisedigitalpartners.com/affordable-web-design/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Powered by</span>{" "}
                    <img src={nestLogo} alt="NEST logo" />
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <div className="flex items-center justify-center lg:justify-start space-x-4">
                <GatsbyImage
                  image={
                    data.equalHousingOpportunity.childImageSharp.gatsbyImageData
                  }
                  alt="Equal-Housing-Opportunity Logo"
                />
                <div className="text-white">
                  <span className="font-bold tracking-wider">NMLS ID</span>
                  <br />
                  <span className="text-sm">#2099559</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ModalContact />
    </>
  );
};

export default Footer;
